<template>
  <v-container fluid class="pa-5">
    <div class="d-flex justify-space-between align-center mb-2">
      <h3 class="text--primary font-weight-black mr-3 d-inline-block">DNA Default Labor Rates</h3>
      <transition name="fade" mode="out-in" appear v-if="canEdit">
        <div key="project-info-vars-btns" class="d-flex" style="gap: 0.5rem">
          <v-btn @click="discard()" :disabled="!hasChanges || loading || saving">
            <i class="fal fa-redo mr-2"></i>Discard
          </v-btn>
          <v-btn
            color="info"
            @click="saveSettings()"
            :loading="saving"
            :disabled="!valid || loading || saving || !hasChanges"
          >
            <i class="fal fa-check mr-2"></i>Save Settings
          </v-btn>
        </div>
      </transition>
    </div>

    <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
      <v-form v-model="valid" ref="settingsForm">
        <div class="d-flex justify-center align-center" v-if="loading">
          <v-progress-circular indeterminate color="info" :width="2"></v-progress-circular>
        </div>
        <div v-else>
          <v-row>
            <v-col cols="12" md="6">
              <v-simple-table dense class="elevation-1">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th class="text-start" style="width: 175px; min-width: 175px">Setting</th>
                      <th class="text-start">Value</th>
                      <th class="text-start">G&A Fee</th>
                      <th class="text-start">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4">
                        <h2 class="font-weight-black py-3">Labor Cost Rates</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Associate Consultant</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.ac"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.ac }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.geFees.ac"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.geFees.ac }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :rules="[allRules.number, allRules.requiredNumber]"
                          :value="selected.loadedLaborRates.ac + selected.geFees.ac"
                          prefix="$"
                          suffix="/hr"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Senior Consultants</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.sc"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.sc }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.geFees.sc"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.geFees.sc }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :rules="[allRules.number, allRules.requiredNumber]"
                          :value="selected.loadedLaborRates.sc + selected.geFees.sc"
                          prefix="$"
                          suffix="/hr"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Consultants</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.c"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.c }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.geFees.c"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.geFees.c }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :rules="[allRules.number, allRules.requiredNumber]"
                          :value="selected.loadedLaborRates.c + selected.geFees.c"
                          prefix="$"
                          suffix="/hr"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Virtual Integrator</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.vi"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.vi }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.geFees.vi"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.geFees.vi }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :rules="[allRules.number, allRules.requiredNumber]"
                          :value="selected.loadedLaborRates.vi + selected.geFees.vi"
                          prefix="$"
                          suffix="/hr"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">BIM Specialist</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.bim"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.bim }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.geFees.bim"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.geFees.bim }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :rules="[allRules.number, allRules.requiredNumber]"
                          :value="selected.loadedLaborRates.bim + selected.geFees.bim"
                          prefix="$"
                          suffix="/hr"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Management</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.management"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>
                          {{ selected.loadedLaborRates.management }}
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.geFees.management"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.geFees.management }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :rules="[allRules.number, allRules.requiredNumber]"
                          :value="selected.loadedLaborRates.management + selected.geFees.management"
                          prefix="$"
                          suffix="/hr"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">Technical</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.technical"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>
                          {{ selected.loadedLaborRates.technical }}
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.geFees.technical"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.geFees.technical }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :rules="[allRules.number, allRules.requiredNumber]"
                          :value="selected.loadedLaborRates.technical + selected.geFees.technical"
                          prefix="$"
                          suffix="/hr"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">SCD</h4>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.loadedLaborRates.scd"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.loadedLaborRates.scd }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.geFees.scd"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.geFees.scd }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :rules="[allRules.number, allRules.requiredNumber]"
                          :value="selected.loadedLaborRates.scd + selected.geFees.scd"
                          prefix="$"
                          suffix="/hr"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="6">
              <v-simple-table dense class="elevation-1">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th class="text-start" style="width: 175px; min-width: 175px">Setting</th>
                      <th class="text-start">Value</th>
                      <th class="text-start">Margin</th>
                      <th class="text-start">Markup</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4">
                        <h2 class="font-weight-black py-3">Labor Sell Rates</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Associate Consultant</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.ac"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.ac }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMargin(
                              selected.sellRates.ac,
                              selected.loadedLaborRates.ac,
                              selected.geFees.ac
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMarkup(
                              selected.sellRates.ac,
                              selected.loadedLaborRates.ac,
                              selected.geFees.ac
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Senior Consultants</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.sc"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.sc }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMargin(
                              selected.sellRates.sc,
                              selected.loadedLaborRates.sc,
                              selected.geFees.sc
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMarkup(
                              selected.sellRates.sc,
                              selected.loadedLaborRates.sc,
                              selected.geFees.sc
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Consultants</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.c"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.c }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMargin(
                              selected.sellRates.c,
                              selected.loadedLaborRates.c,
                              selected.geFees.c
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMarkup(
                              selected.sellRates.c,
                              selected.loadedLaborRates.c,
                              selected.geFees.c
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Virtual Integrator</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.vi"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.vi }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMargin(
                              selected.sellRates.vi,
                              selected.loadedLaborRates.vi,
                              selected.geFees.vi
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMarkup(
                              selected.sellRates.vi,
                              selected.loadedLaborRates.vi,
                              selected.geFees.vi
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">BIM Specialist</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.bim"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.bim }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMargin(
                              selected.sellRates.bim,
                              selected.loadedLaborRates.bim,
                              selected.geFees.bim
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMarkup(
                              selected.sellRates.bim,
                              selected.loadedLaborRates.bim,
                              selected.geFees.bim
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Management</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.management"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.management }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMargin(
                              selected.sellRates.management,
                              selected.loadedLaborRates.management,
                              selected.geFees.management
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMarkup(
                              selected.sellRates.management,
                              selected.loadedLaborRates.management,
                              selected.geFees.management
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">Technical</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.technical"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.technical }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMargin(
                              selected.sellRates.technical,
                              selected.loadedLaborRates.technical,
                              selected.geFees.technical
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMarkup(
                              selected.sellRates.technical,
                              selected.loadedLaborRates.technical,
                              selected.geFees.technical
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                          <div>
                            <h4 class="font-weight-bold">SCD</h4>
                          </div>
                        </div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          v-if="canEdit"
                          :rules="[allRules.number, allRules.requiredNumber]"
                          v-model.number="selected.sellRates.scd"
                          prefix="$"
                          suffix="/hr"
                          :readonly="!canEdit"
                          hide-details
                        >
                        </v-text-field-alt>
                        <div v-else>{{ selected.sellRates.scd }}</div>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMargin(
                              selected.sellRates.scd,
                              selected.loadedLaborRates.scd,
                              selected.geFees.scd
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                      <td class="py-1">
                        <v-text-field-alt
                          :value="
                            calcMarkup(
                              selected.sellRates.scd,
                              selected.loadedLaborRates.scd,
                              selected.geFees.scd
                            )
                          "
                          suffix="%"
                          readonly
                          hide-details
                        >
                        </v-text-field-alt>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import api from "../services/dnaDefaults-service";

export default {
  name: "labor-rates",
  props: {},
  data() {
    return {
      enums,
      perms,
      valid: false,
      hasChanges: false,
      loading: false,
      saving: false,
      selected: {},
      selectedCemented: {},
      min: 0,
      max: 1000,
      step: 1,
    };
  },
  created() {
    this.getLaborRates();
  },
  methods: {
    calcMargin(sellRate, costRate, geFee) {
      return (((sellRate - (costRate + geFee)) / sellRate) * 100).toFixed(2);
    },
    calcMarkup(sellRate, costRate, geFee) {
      return (((sellRate - (costRate + geFee)) / (costRate + geFee)) * 100).toFixed(2);
    },
    getLaborRates() {
      this.loading = true;
      api
        .getLaborRates()
        .then((resp) => {
          this.selected = resp.data;
          this.cementSettings();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveSettings() {
      this.saving = true;
      api
        .saveLaborRates(this.selected)
        .then((resp) => {
          this.selected = resp.data;
          this.cementSettings();
          this.$dialog.notify.success("Company Settings Updated!", {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    checkChanges() {
      this.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    cementSettings() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkChanges();
    },
    discard() {
      this.selected = this.cloneDeep(this.selectedCemented);
      this.checkChanges();
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkChanges();
        this.$emit("change", this.selected);
      },
      deep: true,
    },
    hasChanges() {
      this.$emit("has-changes", this.hasChanges);
    },
    valid() {
      this.$emit("valid-changes", this.valid);
    },
  },
  computed: {
    canEdit() {
      return this.$has(perms.DNADefaults.UpdateLaborRates);
    },
  },
  components: {},
};
</script>

<template>
  <v-container fluid class="px-3 py-4">
    <h2 class="text--primary font-weight-black mb-2">DNA Defaults</h2>
    <v-card v-if="authorizedTabs.length > 0">
      <div
        color="white"
        style="position: sticky; top: 0; z-index: 1"
        @wheel="wheelIt"
        ref="tabsContainer"
      >
        <v-tabs color="error" v-model="tab" show-arrows>
          <v-tab v-for="(item, i) in authorizedTabs" :key="i">
            <v-fab-transition mode="out-in">
              <span class="tab-required-icon" v-if="!item.valid">
                <i class="fas fa-star-of-life pink--text"></i>
              </span>
            </v-fab-transition>
            <i class="fad mr-2" :class="item.icon"></i>
            {{ item.title }}
            <v-fab-transition mode="out-in">
              <span class="tab-changes-icon" v-if="item.hasChange">
                <i class="fas fa-save info--text fa-beat"></i>
              </span>
            </v-fab-transition>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </div>
      <!-- :class="tab == tabs.drawingDocuments.key ? 'project-drawing-document-tab-opened' : ''"
              :class="item.key == tabs.drawingDocuments.key ? 'project-drawing-document-tab' : ''" -->
      <v-tabs-items v-model="tab" :class="`${activeTabClass}-tab-opened`">
        <v-tab-item v-for="(item, i) in authorizedTabs" :key="i" :class="`${item.class}-tab`">
          <keep-alive>
            <labor-rates
              ref="projectInfo"
              key="projectInfo"
              v-if="tab == i && item.key == getTab(tabs.laborRates.id).key"
              @has-changes="tabHasChanged($event, 'laborRates')"
              @valid-changes="tabValidityChanged($event, 'laborRates')"
            ></labor-rates>

            <access-groups
              ref="projectInfo"
              key="projectInfo"
              v-if="tab == i && item.key == getTab(tabs.accessGroup.id).key"
            ></access-groups>
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div class="no-settings-available py-8" v-else>
      <img src="/img/art/tumble-weed.svg" />
      <h3 class="font-weight-bold text--disabled">No settings available, only a green lizard!</h3>
    </div>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import LaborRates from "../components/LaborRates.vue";
import AccessGroups from "../components/AccessGroups.vue";

export default {
  name: "company-settings",
  data() {
    return {
      perms: perms,
      enums: enums,
      tab: 0,
      tabs: {
        laborRates: {
          title: "Labor Rates",
          id: "labor-rates",
          class: "company-settings-labor-rates",
          icon: "fa-circle-dollar",
          valid: true,
          hasChange: false,
          key: 0,
          authorized: this.$has(perms.DNADefaults.ViewLaborRates),
        },
        accessGroup: {
          title: "Access Groups",
          id: "access-groups",
          class: "company-settings-labor-rates",
          icon: "fa-circle-dollar",
          valid: true,
          hasChange: false,
          key: 1,
          authorized: this.$has(perms.DNADefaults.ViewAccessGroups),
        },
      },
    };
  },
  props: {},
  computed: {
    authorizedTabs() {
      var authTabs = Object.values(this.tabs).filter((tab) => tab.authorized);
      for (let i = 0; i < authTabs.length; i++) {
        authTabs[i].key = i;
      }
      return authTabs;
    },
    activeTabClass() {
      // var active = Object.values(this.tabs).find((t) => t.key == this.tab);
      var active = Object.values(this.authorizedTabs).find((t) => t.key == this.tab);
      if (active != null) {
        return active.class;
      }
      return "none";
    },
  },
  created() {},
  mounted() {},
  methods: {
    getTab(id) {
      // var tab = Object.values(this.tabs).find((t) => t.id == id);
      var tab = this.authorizedTabs.find((t) => t.id == id) || {};
      return tab;
    },
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    tabValidityChanged(val, item) {
      this.tabs[item].valid = val;
    },
    tabHasChanged(val, item) {
      this.tabs[item].hasChange = val;
    },
  },
  watch: {},
  components: { LaborRates, AccessGroups },
};
</script>

<style lang="scss">
.type-select-with-bg {
  .v-input__slot {
    min-height: 28px !important;
    padding-left: 0.5rem !important;
    padding-right: 0.15rem !important;
    // box-shadow: none !important;
    background-color: rgba($shades-black, 0.08) !important;

    .v-select__slot {
      height: 28px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  > .v-input__control {
    min-height: 28px !important;
  }

  .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot:not(:hover) {
    background-color: rgba($shades-black, 0.08) !important;
  }
}

.no-data-available {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 350px;
    // opacity: 0.87;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}
.big-board-table table tr > td:not(:first-child),
.big-board-table table tr > th:not(:first-child) {
  border-left: thin solid rgba(42, 54, 59, 0.12);
}
.big-board-table table tr > td .val-positive,
.big-board-table table tr > td .val-zero,
.big-board-table table tr > td .val-negative {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}
.big-board-table table tr > td .val-positive {
  color: $info-base;
  font-weight: 500;
  background: rgba($info-base, 0.08);
}
.big-board-table table tr > td .val-negative {
  color: $accent-base;
  font-weight: 500;
  background: rgba($accent-base, 0.08);
}
.big-board-table table .small-board-group {
  // color: $deep-purple-base;
  font-weight: 500;
  background: rgba($deep-purple-base, 0.06);
}
.no-settings-available {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 400px;
    // opacity: 0.87;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}
</style>
